<template>
  <div>
    <div>

      <el-button @click="InitDrawingBoard()">
        创建画布
      </el-button>
      <el-button @click="DrawLableQrCode()">
        创建内容
      </el-button>
      <el-button @click="generateImagePreviewImage()">
        预览
      </el-button>
      <el-button @click="startJob()">
        开始打印任务
      </el-button>
      <el-button @click="commitJob()">
        提交打印任务
      </el-button>
    </div>
    <div>
      <img :src="imgSrc">
    </div>
  </div>
</template>

<script>
import {
  DrawLableQrCode,
  getInstance,
  init,
  startJob,
  commitJob,
  InitDrawingBoard,
  generateImagePreviewImage,
  DrawLableText, DrawLableLine,
} from "@/utils/jcPrinterSdk_api_third"
export default {
  name: "test",
  data(){
    return{
      initSdkParam: {//初始化数据
        "fontDir": "",
      },

      InitDrawingBoardParam : {
        "width":70,
        "height":100,
        "rotate":0,
        "path":"ZT001.ttf",
        "verticalShift":0,
        "HorizontalShift":0
      },
      DrawTextParam: {
        "x": 20.0,//⽔平坐标
        "y": 45.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 80.0,//宽度， 单位mm
        "value": "施工缝用中埋式普通钢板止水带",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 6.0,//字体⼤⼩
        "textAlignHorizonral": 0,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawLineParam: {
        "x": 5.0,//⽔平坐标
        "y": 50.0,//垂直坐标
        "height": 1,//⾼度，单位mm
        "width": 97.0,//宽度， 单位mm
        "rotate": 90,//旋转⻆度
        "lineType": 1,//线条类型， 1:实线 2:虚线
        "dashwidth": [1,1]//虚线段⻓度
      },
      DrawTextParam1: {
        "x": 25.0,//⽔平坐标
        "y": 25.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 40.0,//宽度， 单位mm
        "value": "规格型号",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 6.0,//字体⼤⼩
        "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawTextParam2: {
        "x": 10.0,//⽔平坐标
        "y": 25.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 50.0,//宽度， 单位mm
        "value": "导爆索(每米装药12克)",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 5.0,//字体⼤⼩
        "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawTextParam3: {
        "x": 3.0,//⽔平坐标
        "y": 25.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 40.0,//宽度， 单位mm
        "value": "计量单位",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 6.0,//字体⼤⼩
        "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawTextParam4: {
        "x": -5.0,//⽔平坐标
        "y": 25.0,//垂直坐标
        "height": 5.0,//⾼度，单位mm
        "width": 40.0,//宽度， 单位mm
        "value": "米",//绘制的⽂本（UTF8）
        "fontFamily": "",
        "rotate": 90,//旋转⻆度
        "fontSize": 5.0,//字体⼤⼩
        "textAlignHorizonral": 1,//⽔平对⻬⽅式，0:左对⻬ 1:居中对⻬ 2:右对⻬
        "textAlignVertical": 1,//垂直对⻬⽅式，0:顶对⻬ 1:垂直居中 2:底对⻬
        "letterSpacing": 0.0,//字间距，单位mm
        "lineSpacing": 1.0,//⾏间距，单位mm
      },
      DrawLableQrCodeParam : {
        "x": 10,
        "y": 55,
        "height": 35,
        "width": 35,
        "value": "",
        "rotate": 0,
        "codeType": 31,
      },
      imgSrc: ''
    }
  },
  created() {
    getInstance();
    init(this.initSdkParam);
  },
  methods:{
    DrawLableQrCode(){
      let that = this;
      this.DrawLableQrCodeParam.value = '9B266C7BFD571AB7289A22FDA0915497';
      DrawLableText(this.DrawTextParam, function (){
        DrawLableLine(that.DrawLineParam, function (){
          DrawLableText(that.DrawTextParam1, function (){
            DrawLableText(that.DrawTextParam2, function (){
              DrawLableText(that.DrawTextParam3, function (){
                DrawLableText(that.DrawTextParam4, function (){
                  DrawLableQrCode(that.DrawLableQrCodeParam, function (){

                  })
                })
              })
            })
          })
        })
      })
      // DrawLableQrCode(this.DrawLableQrCodeParam, function (data){
      //   console.log(data)
      // });
    },
    startJob(){
      startJob(3,1,1,1, function (data){
        console.log(data)
      })
    },
    commitJob(){
      let jsonObj = {"printerImageProcessingInfo": {"printQuantity":1}};
      commitJob(null, JSON.stringify(jsonObj), function (data) {
        console.log(data)
      })
    },
    InitDrawingBoard(){
      InitDrawingBoard(this.InitDrawingBoardParam);
    },
    generateImagePreviewImage(){
      const that = this;
      generateImagePreviewImage(8, function (data){
        const arrParse = JSON.parse(JSON.stringify(data));
        //var Epc = document.getElementById('searchRfidBox');
        const base64Data = arrParse.resultAck.info;
        const obj = JSON.parse(base64Data);
        const data1 = obj.ImageData;
        that.imgSrc = "data:image/jpeg;base64," + data1;
      })
    }
  }
}
</script>
<style scoped>

</style>
